import React from 'react'
import { Container, Row, Col, Image, Breadcrumb, BreadcrumbItem, Button } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { FaCheck } from 'react-icons/fa'
import OverImage from '../assets/images/cms.png'
import OverPImage from '../assets/images/over-p.png'
import WaaromImage from '../assets/images/waarom.webp'

const CMSScreen = () => {
  return (
    <Container>
      <header >
        <Row>
          <Col className='header-image'>
            <Image src={OverImage} fluid/>
          </Col>
        </Row>
      </header>
      <Row>
        <Col>
          <Breadcrumb>
            <LinkContainer to='/'>
              <BreadcrumbItem>
              Home
              </BreadcrumbItem>
            </LinkContainer>
            <BreadcrumbItem active>Diensten</BreadcrumbItem>
            <BreadcrumbItem active>Content Management Systems (CMS)</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <h1 className='section-header'>Content Management Systems (CMS)</h1>
        <section className="section-body">
          <Col>
            <p className='section-paragraph text-justify'>
            Bij Erjel begrijpen we de cruciale rol die effectief contentmanagement speelt bij het behouden van een dynamische en boeiende online aanwezigheid. Onze Content Management Systems (CMS)-service is ontworpen om u te voorzien van de tools en technologie die nodig zijn om de inhoud van uw website moeiteloos te creëren, bij te werken en te beheren. Of u nu een klein bedrijf bent dat een online aanwezigheid wil opbouwen of een grote onderneming bent die een complex web-ecosysteem beheert, onze CMS-oplossingen zijn afgestemd op uw unieke behoeften.
            </p>
          </Col>
          <Col>
            <Image src={OverPImage} thumbnail />
          </Col>
        </section>
      </Row>
      <Row>
        <h1 className='section-header'>Belangrijkste kenmerken van Content Management Systems (CMS)</h1>
        <section className="section-body">
          <Col>
            <Image src={WaaromImage} thumbnail />
          </Col>
          <Col>
          <h4><FaCheck /> Intuïtieve contentcreatie</h4>
            <p className='section-paragraph text-justify'>
            Onze CMS-platforms bieden een gebruiksvriendelijke en intuïtieve contenteditor waarmee u tekst kunt maken en opmaken, afbeeldingen en video's kunt uploaden en multimedia-elementen naadloos kunt insluiten. Je hebt geen uitgebreide technische kennis nodig om boeiende inhoud te publiceren.
            </p>
          <h4><FaCheck /> Inhoudsorganisatie</h4>
            <p className='section-paragraph text-justify'>
            We implementeren robuuste taxonomie- en categorisatiesystemen waarmee u uw inhoud logisch kunt ordenen. Dit zorgt ervoor dat uw website gestructureerd is voor gemakkelijke navigatie en doorzoekbaarheid.
            </p>
          <h4><FaCheck /> Gebruikersrollen en machtigingen</h4>
            <p className='section-paragraph text-justify'>
            Onze CMS-oplossingen bieden op rollen gebaseerde toegangscontrole, waardoor u specifieke machtigingen aan gebruikers kunt toewijzen op basis van hun rollen binnen uw organisatie. Dit zorgt ervoor dat alleen geautoriseerde gebruikers wijzigingen kunnen aanbrengen op uw website.
            </p>
          <h4><FaCheck /> Versiebeheer</h4>
            <p className='section-paragraph text-justify'>
            Versiebeheerfuncties stellen u in staat wijzigingen in uw inhoud in de loop van de tijd bij te houden. U kunt indien nodig eerdere versies bekijken en terugzetten, waardoor u transparantie en verantwoordelijkheid bij het inhoudsbeheer krijgt.
            </p>
            <h4><FaCheck /> Workflowbeheer</h4>
            <p className='section-paragraph text-justify'>
            Voor organisaties met meerdere makers en redacteuren van inhoud ondersteunen onze CMS-platforms workflowbeheer. U kunt goedkeuringsprocessen voor inhoud definiëren, taken toewijzen en het proces voor het maken en beoordelen van inhoud stroomlijnen.
            </p>
            <h4><FaCheck /> Aanpasbare sjablonen</h4>
            <p className='section-paragraph text-justify'>
            We bieden aanpasbare sjablonen en thema's die aansluiten bij uw merkidentiteit. Of u nu op zoek bent naar een strakke huisstijl of een levendig e-commerce thema, onze CMS-platforms bieden flexibiliteit in ontwerp.
            </p>
            <h4><FaCheck /> Meertalige ondersteuning</h4>
            <p className='section-paragraph text-justify'>
            Als u een wereldwijd publiek heeft, ondersteunen onze CMS-oplossingen meertalige inhoud, waardoor het gemakkelijker wordt om inhoud in verschillende talen en regio's te beheren.
            </p>
            <h4><FaCheck /> SEO-optimalisatie</h4>
            <p className='section-paragraph text-justify'>
            Onze CMS-platforms zijn uitgerust met SEO-vriendelijke functies, waaronder aanpasbare URL's, metatags en sitemaps, waarmee u de zichtbaarheid van uw website in de resultaten van zoekmachines kunt verbeteren.
            </p>
            <h4><FaCheck /> Integraties van derden</h4>
            <p className='section-paragraph text-justify'>
            We integreren tools en diensten van derden naadloos, of het nu gaat om analyses, het delen van sociale media, e-commerce of marketingautomatisering, waardoor de functionaliteit van uw website wordt uitgebreid.
            </p>
          </Col>
        </section>
      </Row>
      <Row>
        <h1 className='section-header'>Voordelen van onze Content Management Systems (CMS)</h1>
        <section className="section-body">
          <Col>
            <h4><FaCheck /> Efficiëntie</h4>
            <p className='section-paragraph text-justify'>
            Gestroomlijnde contentbeheerprocessen besparen tijd en moeite, zodat u zich kunt concentreren op het creëren van inhoud van hoge kwaliteit.
            </p>
            <h4><FaCheck /> Consistentie</h4>
            <p className='section-paragraph text-justify'>
            CMS-oplossingen zorgen voor een consistente look en feel op uw hele website, waardoor uw merkidentiteit wordt versterkt.
            </p>
            <h4><FaCheck /> Schaalbaarheid</h4>
            <p className='section-paragraph text-justify'>
            Onze CMS-platforms zijn schaalbaar en kunnen omgaan met de toegenomen inhoud en gebruikersbehoeften naarmate uw bedrijf groeit.
            </p>
            <h4><FaCheck /> Beveiliging</h4>
            <p className='section-paragraph text-justify'>
            Wij geven prioriteit aan beveiliging en implementeren maatregelen om uw website te beschermen tegen bedreigingen en kwetsbaarheden.
            </p>
            <h4><FaCheck /> Kosteneffectiviteit</h4>
            <p className='section-paragraph text-justify'>
            Onze CMS-oplossingen bieden kosteneffectief contentbeheer, waardoor de behoefte aan voortdurende technische ondersteuning wordt verminderd.
            </p>
            <h4><FaCheck /> Toegankelijkheid van inhoud</h4>
            <p className='section-paragraph text-justify'>
            Met gebruiksvriendelijke interfaces kan zelfs niet-technisch personeel bijdragen aan het creëren en bijwerken van inhoud.
            </p>
          </Col>
          <Col className='section-image'>
            <Image src={WaaromImage} thumbnail />
          </Col>
        </section>
      </Row>
      <Row>
        <h1 className='section-header'>Kortom</h1>
        <section className="section-body">
          <Col>
            <Image src={OverPImage} thumbnail />
          </Col>
          <Col>
            <p className='section-paragraph text-justify'>
            Bij Erjel geloven we dat effectief contentmanagement de basis is van een succesvolle online aanwezigheid. Met onze CMS-service kunt u de controle over uw digitale inhoud overnemen, waardoor u de flexibiliteit, efficiëntie en schaalbaarheid krijgt die nodig is om te gedijen in het digitale landschap. Of u nu een nieuwe website lanceert of uw huidige website wilt verbeteren, onze CMS-oplossingen zijn ontworpen om aan uw behoeften op het gebied van contentbeheer te voldoen. Neem vandaag nog contact met ons op om te bespreken hoe onze CMS-service uw online contentstrategie radicaal kan veranderen.
            </p>
          </Col>
        </section>
      </Row>
      <div className='d-grid'>
        <LinkContainer to='/contact'>
          <Button size="lg">
            {' '} Neem contact met Ons
          </Button>
        </LinkContainer>
      </div>
    </Container>
  )
}

export default CMSScreen