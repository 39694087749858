import React from 'react'
import { Container, Row, Col, Image, Breadcrumb, BreadcrumbItem, Button } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { FaCheck } from 'react-icons/fa'
import OverImage from '../assets/images/ecommerce.png'
import OverPImage from '../assets/images/over-p.png'
import WaaromImage from '../assets/images/waarom.webp'

const EcommerceScreen = () => {
  return (
    <Container>
      <header >
        <Row>
          <Col className='header-image'>
            <Image src={OverImage} fluid/>
          </Col>
        </Row>
      </header>
      <Row>
        <Col>
          <Breadcrumb>
            <LinkContainer to='/'>
              <BreadcrumbItem>
                  Home
              </BreadcrumbItem>
            </LinkContainer>
            <BreadcrumbItem active>Diensten</BreadcrumbItem>
            <BreadcrumbItem active>E-Commerce</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <h1 className='section-header'>E-Commerce</h1>
        <section className="section-body">
          <Col>
            <p className='section-paragraph text-justify'>
            In het digitale tijdperk is e-commerce een hoeksteen van het moderne bedrijfsleven geworden. Bij Erjel zijn we gespecialiseerd in het creëren van robuuste en veilige e-commerceplatforms waarmee bedrijven producten en diensten effectief online kunnen verkopen. Onze e-commerceoplossingen zijn ontworpen om uw online aanwezigheid te verbeteren, uw verkoopproces te stroomlijnen en uw klanten een uitzonderlijke winkelervaring te bieden.
            </p>
          </Col>
          <Col>
            <Image src={OverPImage} thumbnail />
          </Col>
        </section>
      </Row>
      <Row>
        <h1 className='section-header'>Belangrijkste kenmerken van onze E-commerce Solutions</h1>
        <section className="section-body">
          <Col>
            <Image src={WaaromImage} thumbnail />
          </Col>
          <Col>
          <h4><FaCheck /> Gebruiksvriendelijke online winkels</h4>
            <p className='section-paragraph text-justify'>
            We ontwerpen intuïtieve en visueel aantrekkelijke online winkels waarmee klanten gemakkelijk door producten kunnen bladeren, zoeken en kopen. Onze gebruikersinterfaces geven prioriteit aan vlotte navigatie en snelle toegang tot producten.
            </p>
          <h4><FaCheck /> Veilige betalingsverwerking</h4>
            <p className='section-paragraph text-justify'>
            Veiligheid is van het allergrootste belang in e-commerce. We integreren veilige betalingsgateways om ervoor te zorgen dat klanttransacties worden beschermd en dat gevoelige gegevens met de grootste zorg worden behandeld.
            </p>
          <h4><FaCheck /> Voorraadbeheer</h4>
            <p className='section-paragraph text-justify'>
            Onze systemen bevatten hulpmiddelen voor voorraadbeheer waarmee u de beschikbaarheid van producten kunt bijhouden, het aanvullen van voorraden kunt automatiseren en oververkoop kunt voorkomen.
            </p>
          <h4><FaCheck /> Mobiele optimalisatie</h4>
            <p className='section-paragraph text-justify'>
            Met de opkomst van mobiel winkelen zijn onze e-commerceoplossingen volledig geoptimaliseerd voor mobiele apparaten, waardoor een naadloze winkelervaring op smartphones en tablets wordt geboden.
            </p>
            <h4><FaCheck /> Productcatalogi</h4>
            <p className='section-paragraph text-justify'>
            We creëren dynamische productcatalogi met opties voor verschillende categorieën, variaties en productkenmerken. Hierdoor kunt u uw producten effectief onder de aandacht brengen.
            </p>
            <h4><FaCheck /> Klantenbeheer</h4>
            <p className='section-paragraph text-justify'>
            Onze systemen omvatten klantprofielen, bestelgeschiedenissen en gepersonaliseerde aanbevelingen om het klantbehoud en de tevredenheid te verbeteren.
            </p>
            <h4><FaCheck /> Orderverwerking</h4>
            <p className='section-paragraph text-justify'>
            We integreren systemen voor orderverwerking en afhandeling om het verzend- en leveringsproces te stroomlijnen en ervoor te zorgen dat bestellingen efficiënt worden verwerkt.
            </p>
            <h4><FaCheck /> Beveiligingsmaatregelen</h4>
            <p className='section-paragraph text-justify'>
            We implementeren robuuste beveiligingsmaatregelen om zowel uw bedrijf als uw klanten te beschermen. Dit omvat SSL-codering, PCI-compliance en regelmatige beveiligingsaudits.
            </p>
            <h4><FaCheck /> Schaalbaarheid</h4>
            <p className='section-paragraph text-justify'>
            Onze e-commerceplatforms zijn gebouwd om met uw bedrijf mee te groeien. Of u nu begint met een kleine catalogus en deze in de loop van de tijd uitbreidt, of dat u al een grote onderneming bent, onze oplossingen kunnen dienovereenkomstig worden geschaald.
            </p>
          </Col>
        </section>
      </Row>
      <Row>
        <h1 className='section-header'>Voordelen van onze E-Commerce Solutions</h1>
        <section className="section-body">
          <Col>
            <h4><FaCheck /> Verhoogde omzet</h4>
            <p className='section-paragraph text-justify'>
            een goed geoptimaliseerd e-commerceplatform biedt klanten een gemakkelijke winkelervaring, wat leidt tot hogere conversiepercentages en hogere verkopen.
            </p>
            <h4><FaCheck /> Mondiaal bereik</h4>
            <p className='section-paragraph text-justify'>
            Vergroot uw marktbereik voorbij geografische grenzen. Met onze e-commerceoplossingen kunt u aan klanten over de hele wereld verkopen.
            </p>
            <h4><FaCheck /> Datagestuurde inzichten</h4>
            <p className='section-paragraph text-justify'>
            Onze systemen bieden waardevolle gegevens en analyses, zodat u weloverwogen beslissingen kunt nemen over marketing, productaanbod en klantbetrokkenheid.
            </p>
            <h4><FaCheck /> Merkloyaliteit</h4>
            <p className='section-paragraph text-justify'>
            een naadloze en veilige winkelervaring bouwt vertrouwen en merkloyaliteit op bij uw klanten.
            </p>
            <h4><FaCheck /> Gestroomlijnde activiteiten</h4>
            <p className='section-paragraph text-justify'>
            Automatiseringsfuncties zoals voorraadbeheer en orderverwerking stroomlijnen uw bedrijfsactiviteiten en verminderen handmatige inspanningen.
            </p>
          </Col>
          <Col>
            <Image src={WaaromImage} thumbnail />
          </Col>
        </section>
      </Row>
      <Row>
        <h1 className='section-header'>Kortom</h1>
        <section className="section-body">
          <Col>
            <Image src={OverPImage} thumbnail />
          </Col>
          <Col>
            <p className='section-paragraph text-justify'>
            Bij Erjel begrijpen we dat succes op het gebied van e-commerce meer vereist dan alleen een winkelwagentje. Het vereist een alomvattende strategie die gebruikerservaring, beveiliging en functionaliteit combineert. Onze e-commerceoplossingen zijn afgestemd op de unieke behoeften van uw bedrijf en helpen u uw verkoop- en groeidoelstellingen te bereiken. Laat ons u helpen een online winkel te creëren die niet alleen producten verkoopt, maar ook gedenkwaardige winkelervaringen voor uw klanten creëert. Neem vandaag nog contact met ons op om te ontdekken hoe wij uw e-commercevisie kunnen omzetten in realiteit.
            </p>
          </Col>
        </section>
      </Row>
      <div className='d-grid'>
        <LinkContainer to='/contact'>
          <Button size="lg">
            {' '} Neem contact met Ons
          </Button>
        </LinkContainer>
      </div>
    </Container>
  )
}

export default EcommerceScreen