import React from 'react'
import { Container, Row, Col, Image, Button } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import ImageSlider from '../components/ImageSlider'

import OverPImage from '../assets/images/over-p.png'

import SliderImage from '../assets/images/slides/slide-1.webp'
import SliderImage1 from '../assets/images/slides/slide-2.webp'
import SliderImage2 from '../assets/images/slides/slide-3.webp'
import SliderImage3 from '../assets/images/slides/slide-4.webp'
import SliderImage4 from '../assets/images/slides/slide-5.webp'
import SliderImage5 from '../assets/images/slides/slide-6.webp'

const HomeScreen = () => {
  const images = [
    SliderImage,
    SliderImage1,
    SliderImage2,
    SliderImage3,
    SliderImage4,
    SliderImage5
  ];

  return (
    <>
      <Container>
        <header >
          <Row>
            <Col>
              <ImageSlider images={images} />
            </Col>
          </Row>
        </header>
        <Row>
          <h1 className='section-header'>Welkom naar Erjel ICT</h1>
          <section className="section-body">
            <Col>
              <p className='section-paragraph text-justify'>
              Erjel is een professioneel ontwikkelingsbedrijf dat zich toegelegt op het creëren van innovatieve en effectieve weboplossingen voor bedrijven van elke omvang. Met een team van zeer bekwame ontwikkelaars en ontwerpers is Erjel gespecialiseerd in het creëren van responsieve, gebruiksvriendelijke en visueel verbluffende websites die resultaten opleveren en uw online aanwezigheid naar hoger niveau tillen. Als u nu een startup bent die uw digitale voetafdruk wilt vestigen of een gevestigd merk dat op zoek is naar een vernieuwing van de website, heeft Erjel de expertise om uw visie tot leven te brengen.
              </p>
            </Col>
            <Col>
              <Image src={OverPImage} thumbnail />
            </Col>
          </section>
        </Row>
        <div className='d-grid'>
          <LinkContainer to='/contact'>
            <Button size="lg">
              {' '} Neem contact met Ons
            </Button>
          </LinkContainer>
        </div>
      </Container>
    </>
  )
}

export default HomeScreen