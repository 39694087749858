import React from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import {Nav, Container, Navbar, NavbarBrand, NavbarToggle, NavbarCollapse, Image, NavDropdown } from 'react-bootstrap'
import { FaFileAlt, FaUser, FaCode } from 'react-icons/fa'
import Logo from '../assets/logo.png'
import '../assets/index.css'

const Header = () => {
  return (
    <header>
      <Navbar style={{background: '#B2282F'}} variant="dark" expand="md" collapseOnSelect>
        <Container>
          <LinkContainer to='/'>
            <NavbarBrand>
              <Image src={Logo} fluid/>
              Erjel ICT
            </NavbarBrand>
          </LinkContainer>
          <NavbarToggle aria-controls='basic-navbar-nav' />
          <NavbarCollapse id="basic-navbar-nav" className="justify-content-end">
            <Nav className='ms-auto'>
              <LinkContainer to='/about'>
                <Nav.Link> 
                  <FaFileAlt size={16}/>{' '}
                  Over Erjel 
                </Nav.Link>
              </LinkContainer>
              <NavDropdown title={<span><FaCode size={19}/>{' Diensten'}</span>} id="basic-nav-dropdown" >
                <LinkContainer to='/webdesign'>
                  <NavDropdown.Item>
                    Web Design
                  </NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to='/frontend'>
                  <NavDropdown.Item>
                    Front-End Development
                  </NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to='/backend'>
                  <NavDropdown.Item>
                    Back-End Development
                  </NavDropdown.Item>
                </LinkContainer>
                <NavDropdown.Divider />
                <LinkContainer to='/ecommerce'>
                  <NavDropdown.Item>
                    E-Commerce
                  </NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to='/mobileapp'>
                  <NavDropdown.Item>
                    Mobile App Development
                  </NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to='/cmsscreen'>
                  <NavDropdown.Item>
                    Content Management System
                  </NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to='/seoscreen'>
                  <NavDropdown.Item>
                    Search Engine Optimization
                  </NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
              <LinkContainer to='/contact'>
                <Nav.Link> 
                  <FaUser size={15}/>{' '}
                  Contact 
                </Nav.Link>
              </LinkContainer>
            </Nav>
          </NavbarCollapse>
        </Container>
      </Navbar>    
    </header>
  )
}

export default Header