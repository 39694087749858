import React from 'react'
import { Container, Row, Col, Image, Breadcrumb, BreadcrumbItem, Button } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { FaCheck } from 'react-icons/fa'
import OverImage from '../assets/images/SEO.png'
import OverPImage from '../assets/images/over-p.png'
import WaaromImage from '../assets/images/waarom.webp'

const SEOScreen = () => {
  return (
    <Container>
      <header >
        <Row>
          <Col className='header-image'>
            <Image src={OverImage} fluid/>
          </Col>
        </Row>
      </header>
      <Row>
        <Col>
          <Breadcrumb>
            <LinkContainer to='/'>
              <BreadcrumbItem>
              Home
              </BreadcrumbItem>
            </LinkContainer>
            <BreadcrumbItem active>Diensten</BreadcrumbItem>
            <BreadcrumbItem active>Search Engine Optimization (SEO)</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <h1 className='section-header'>Search Engine Optimization (SEO)</h1>
        <section className="section-body">
          <Col>
            <p className='section-paragraph text-justify'>
            In het digitale tijdperk is gemakkelijk vindbaar zijn op zoekmachines van cruciaal belang voor het succes van uw online aanwezigheid. Bij Erjel is onze Search Engine Optimization (SEO)-service bedoeld om uw website hoger te laten scoren op de resultatenpagina's van zoekmachines (SERP's) en om organisch verkeer aan te trekken. We hanteren een alomvattende aanpak die technische expertise, contentoptimalisatie en strategische marketing combineert om uw online zichtbaarheid te vergroten en meetbare resultaten te behalen.
            </p>
          </Col>
          <Col>
            <Image src={OverPImage} thumbnail />
          </Col>
        </section>
      </Row>
      <Row>
        <h1 className='section-header'>Onze aanpak voor Search Engine Optimization (SEO)</h1>
        <section className="section-body">
          <Col>
            <Image src={WaaromImage} thumbnail />
          </Col>
          <Col>
          <h4><FaCheck /> Zoekwoordonderzoek en -analyse</h4>
            <p className='section-paragraph text-justify'>
            We beginnen met het uitvoeren van diepgaand trefwoordonderzoek om de termen en zinsneden te identificeren die relevant zijn voor uw bedrijf en doelgroep. Dit vormt de basis van onze SEO-strategie.
            </p>
          <h4><FaCheck /> SEO op de pagina</h4>
            <p className='section-paragraph text-justify'>
            Onze experts optimaliseren elementen op de pagina, zoals metatags, koppen en inhoud, zodat ze aansluiten op gerichte zoekwoorden. Dit omvat het verbeteren van paginatitels, metabeschrijvingen en alt-tags voor afbeeldingen.
            </p>
          <h4><FaCheck /> Contentoptimalisatie</h4>
            <p className='section-paragraph text-justify'>
            Hoogwaardige, relevante inhoud is de sleutel tot SEO-succes. Wij zorgen ervoor dat de inhoud van uw website informatief, boeiend en geoptimaliseerd is voor zoekmachines, waardoor gebruikers worden aangemoedigd langer te blijven en verder te verkennen.
            </p>
          <h4><FaCheck /> Technische SEO</h4>
            <p className='section-paragraph text-justify'>
            We behandelen de technische aspecten van uw website die van invloed zijn op de ranking van zoekmachines, waaronder websitesnelheid, mobielvriendelijkheid, schema-opmaak en XML-sitemaps.
            </p>
            <h4><FaCheck /> Linkbuilding</h4>
            <p className='section-paragraph text-justify'>
            Het bouwen van hoogwaardige backlinks van gezaghebbende websites is een cruciaal onderdeel van onze strategie. We gebruiken ethische technieken voor het opbouwen van links om de geloofwaardigheid van uw website te vergroten.
            </p>
            <h4><FaCheck /> Lokale SEO</h4>
            <p className='section-paragraph text-justify'>
            Voor bedrijven met een fysieke aanwezigheid optimaliseren we uw online aanwezigheid voor lokale zoekopdrachten, zodat u zeker weet dat u in lokale zoekresultaten en op kaarten verschijnt.
            </p>
            <h4><FaCheck /> Analyses en rapportage</h4>
            <p className='section-paragraph text-justify'>
            We bieden regelmatig rapporten en analyses die de voortgang van uw SEO-inspanningen laten zien, inclusief verbeteringen in de rangschikking van zoekwoorden, verkeersgroei en het bijhouden van conversies.
            </p>
          </Col>
        </section>
      </Row>
      <Row>
        <h1 className='section-header'>Voordelen van onze Search Engine Optimization (SEO)</h1>
        <section className="section-body">
          <Col>
            <h4><FaCheck /> Verbeterde zichtbaarheid</h4>
            <p className='section-paragraph text-justify'>
            Onze SEO-strategieën zijn erop gericht de zichtbaarheid van uw website in zoekmachines te vergroten, waardoor het voor potentiële klanten gemakkelijker wordt om u te vinden.
            </p>
            <h4><FaCheck /> Meer organisch verkeer</h4>
            <p className='section-paragraph text-justify'>
            Door uw website te optimaliseren voor zoekopdrachten, helpen we u meer organisch verkeer aan te trekken, waardoor u minder afhankelijk bent van betaalde advertenties.
            </p>
            <h4><FaCheck /> Betere gebruikerservaring</h4>
            <p className='section-paragraph text-justify'>
            SEO-optimalisatie leidt vaak tot verbeteringen in de websitesnelheid, mobielvriendelijkheid en algehele gebruikerservaring, waardoor het bouncepercentage kan worden verlaagd en de betrokkenheid kan worden verbeterd.
            </p>
            <h4><FaCheck /> Meetbare resultaten</h4>
            <p className='section-paragraph text-justify'>
            We bieden transparante rapportage en analyses, zodat u de impact van onze SEO-inspanningen kunt volgen en weloverwogen beslissingen kunt nemen.
            </p>
            <h4><FaCheck /> Concurrentievoordeel</h4>
            <p className='section-paragraph text-justify'>
            SEO kan u helpen de concurrentie te overtreffen in de zoekresultaten, waardoor uw bedrijf wordt gepositioneerd als een vertrouwde autoriteit in uw branche.
            </p>
            <h4><FaCheck /> Kostenefficiëntie</h4>
            <p className='section-paragraph text-justify'>
            Vergeleken met veel andere marketingstrategieën biedt SEO een hoog rendement op de investering (ROI) door duurzame resultaten op de lange termijn te leveren.
            </p>
          </Col>
          <Col className='section-image'>
            <Image src={WaaromImage} thumbnail />
          </Col>
        </section>
      </Row>
      <Row>
        <h1 className='section-header'>Kortom</h1>
        <section className="section-body">
          <Col>
            <Image src={OverPImage} thumbnail />
          </Col>
          <Col>
            <p className='section-paragraph text-justify'>
            Bij Erjel begrijpen we dat SEO geen one-size-fits-all oplossing is. Onze SEO-service is aangepast om te voldoen aan de unieke behoeften van uw bedrijf, branche en doelgroep. We doen er alles aan om op de hoogte te blijven van de nieuwste zoekmachinealgoritmen en best practices om ervoor te zorgen dat uw website concurrerend blijft in het steeds evoluerende digitale landschap. Laat ons u helpen het potentieel van SEO te ontsluiten en blijvend online succes te behalen. Neem vandaag nog contact met ons op om te bespreken hoe onze SEO-service de groei van uw bedrijf kan stimuleren.
            </p>
          </Col>
        </section>
      </Row>
      <div className='d-grid'>
        <LinkContainer to='/contact'>
          <Button size="lg">
            {' '} Neem contact met Ons
          </Button>
        </LinkContainer>
      </div>
    </Container>
  )
}

export default SEOScreen