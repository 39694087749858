import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

const Footer = () => {
    const currentYear = new Date().getFullYear()
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col md={4} sm={6}>
            <h5>Erjel ICT Services</h5>
            <p>
              "Vereenvoudig uw online reis, versterkt met beveiliging."
            </p>
          </Col>
          <Col md={4} sm={6}>
            <h5>Neem Contact op</h5>
            <p>
              {/* Erjel ICT Services<br /> <br /> */}
              Dilgtweg 5B/105<br />
              Groningen, Netherlands <br /> <br />
              +31 (0) 630 527 571<br /> 
              info@erjel.nl
            </p>
          </Col>
          <Col md={4} sm={6}>
            <h5>Belangrijke Links</h5>
            <LinkContainer to='/about'>
              <div className='cursor-pointer'>Over Erjel</div>
            </LinkContainer>
            <LinkContainer to='/contact'>
              <div className='cursor-pointer'>Contact</div>
            </LinkContainer>
            <LinkContainer to='/privacy-policy'>
              <div className='cursor-pointer'>Privacy Policy</div>
            </LinkContainer>
          </Col>
          <Col md={4} sm={12}>
            {/* <h5>Voor bedrijven</h5> */}
            <p>
              Kvk: 86304593<br />
              BTW: NL004223423B74
            </p>
          </Col>
          <Row>
            <Col className='text-center py-3'>
                <p>Erjel &copy; {currentYear}</p>
            </Col>
          </Row>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer