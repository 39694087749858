import React from 'react'
import { Container, Row, Col, Image, Breadcrumb, BreadcrumbItem, Button } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { FaCheck } from 'react-icons/fa'
import OverImage from '../assets/images/backend.png'
import OverPImage from '../assets/images/over-p.png'
import WaaromImage from '../assets/images/waarom.webp'


const BackendScreen = () => {
  return (
    <Container>
      <header >
        <Row>
          <Col className='header-image'>
            <Image src={OverImage} fluid/>
          </Col>
        </Row>
      </header>
      <Row>
        <Col>
          <Breadcrumb>
            <LinkContainer to='/'>
              <BreadcrumbItem>
                  Home
              </BreadcrumbItem>
            </LinkContainer>
            <BreadcrumbItem active>Diensten</BreadcrumbItem>
            <BreadcrumbItem active>Back-End Development</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <h1 className='section-header'>Back-End Development</h1>
        <section className="section-body">
          <Col>
            <p className='section-paragraph text-justify'>
              Back-End Development vormt de ruggengraat van uw website en is verantwoordelijk voor de functionaliteit, het gegevensbeheer en de algehele prestaties waar uw gebruikers op vertrouwen. Bij Erjel zijn we trots op onze Back-End Development-service, waarbij complexe technische architectuur en naadloze functionaliteit samenkomen om websites te creëren die er niet alleen goed uitzien, maar ook feilloos werken.
            </p>
          </Col>
          <Col>
            <Image src={OverPImage} thumbnail />
          </Col>
        </section>
      </Row>
      <Row>
        <h1 className='section-header'>Onze Back-End ontwikkelingsexpertise</h1>
        <section className="section-body">
          <Col>
            <Image src={WaaromImage} thumbnail />
          </Col>
          <Col>
          <h4><FaCheck /> Databaseontwerp</h4>
            <p className='section-paragraph text-justify'>
            Onze Back-endontwikkelaars blinken uit in het ontwerpen en optimaliseren van databases om de gegevens van uw website efficiënt op te slaan en te beheren. Of het nu gaat om gebruikersprofielen, productcatalogi of inhoudsbibliotheken, wij garanderen gegevensintegriteit en snelle toegang.
            </p>
            <h4><FaCheck /> Server-side scripting</h4>
            <p className='section-paragraph text-justify'>
            We gebruiken server-side scripttalen zoals PHP en Node.js om dynamische webapplicaties te ontwikkelen. Dit maakt realtime interacties, gepersonaliseerde inhoudslevering en gebruikersauthenticatie mogelijk.
            </p>
            <h4><FaCheck /> API-integratie</h4>
            <p className='section-paragraph text-justify'>
            We integreren API's en services van derden om de functionaliteit van uw website uit te breiden, of het nu gaat om betalingsgateways, integratie van sociale media of externe gegevensbronnen. Dit verbetert de gebruikerservaring en biedt extra functies voor uw publiek.
            </p>
            <h4><FaCheck /> Content Management Systemen (CMS)</h4>
            <p className='section-paragraph text-justify'>
            Onze ontwikkelaars kunnen CMS-oplossingen zoals WordPress, Joomla of Drupal implementeren of aanpassen, zodat u de inhoud van uw website eenvoudig kunt beheren. Wij stemmen het CMS af op uw specifieke wensen en voorkeuren.
            </p>
            <h4><FaCheck /> E-commerceoplossingen</h4>
            <p className='section-paragraph text-justify'>
            Voor online winkels bouwen we robuuste e-commerceplatforms met functies zoals voorraadbeheer, veilige betalingsverwerking, ordertracking en klantenbeheer. Wij zorgen voor vlotte transacties en een veilige winkelervaring.
            </p>
            <h4><FaCheck /> Beveiligingsmaatregelen</h4>
            <p className='section-paragraph text-justify'>
            Het beschermen van uw website en gebruikersgegevens is van het allergrootste belang. We implementeren beveiligingsmaatregelen zoals encryptie, gebruikersauthenticatie en regelmatige beveiligingsaudits om uw digitale activa te beschermen.
            </p>
            <h4><FaCheck /> Schaalbaarheid</h4>
            <p className='section-paragraph text-justify'>
            Onze back-endontwikkelaars ontwerpen systemen die kunnen meeschalen met uw bedrijf. Of u nu klein begint en groei plant, of te maken heeft met grote verkeersvolumes, onze oplossingen zijn gebouwd om het allemaal aan te kunnen.
            </p>
          </Col>
        </section>
      </Row>
      <Row>
        <h1 className='section-header'>Onze Back-End ontwikkelingsexpertise</h1>
        <section className="section-body">
          <Col>
          <h4><FaCheck /> Naadloze Functionaliteit</h4>
            <p className='section-paragraph text-justify'>
            Onze back-endontwikkeling zorgt ervoor dat de functies en functionaliteiten van uw website soepel werken en een positieve gebruikerservaring bieden.
            </p>
            <h4><FaCheck /> Gegevensintegriteit</h4>
            <p className='section-paragraph text-justify'>
            We zetten robuuste gegevensbeheersystemen op, die ervoor zorgen dat uw gegevens georganiseerd, veilig en gemakkelijk toegankelijk zijn.
            </p>
            <h4><FaCheck /> Aangepaste Oplossingen</h4>
            <p className='section-paragraph text-justify'>
            we stemmen back-endsystemen af op uw unieke bedrijfsvereisten, waardoor onnodige complexiteit wordt vermeden en de activiteiten worden gestroomlijnd.
            </p>
            <h4><FaCheck /> Verbeterde Prestaties</h4>
            <p className='section-paragraph text-justify'>
            Efficiënte back-endarchitectuur leidt tot snellere laadtijden, waardoor gebruikersfrustratie wordt verminderd en de betrokkenheid toeneemt.
            </p>
            <h4><FaCheck /> Kostenefficiëntie</h4>
            <p className='section-paragraph text-justify'>
            Onze oplossingen zijn ontworpen om de onderhoudskosten op de lange termijn te minimaliseren en tegelijkertijd de betrouwbaarheid en prestaties te maximaliseren.
            </p>
          </Col>
          <Col>
            <Image src={WaaromImage} thumbnail />
          </Col>
        </section>
      </Row>
      <Row>
        <h1 className='section-header'>Kortom</h1>
        <section className="section-body">
          <Col>
            <Image src={OverPImage} thumbnail />
          </Col>
          <Col>
            <p className='section-paragraph text-justify'>
              Bij Erjel is onze Back-End Development-service de motor die uw website aandrijft, waardoor ontwerpconcepten worden omgezet in functionele realiteit. We hebben een passie voor het creëren van betrouwbare en schaalbare oplossingen die niet alleen aan uw huidige behoeften voldoen, maar ook de weg vrijmaken voor toekomstige groei. Laat ons de technische complexiteiten afhandelen terwijl u zich concentreert op het leveren van uitzonderlijke inhoud en diensten aan uw publiek. Neem vandaag nog contact met ons op om uw behoeften op het gebied van back-endontwikkeling te bespreken en uw aanwezigheid op het web naar een hoger niveau te tillen.
            </p>
          </Col>
        </section>
      </Row>
      <div className='d-grid'>
        <LinkContainer to='/contact'>
          <Button size="lg">
            {' '} Neem contact met Ons
          </Button>
        </LinkContainer>
      </div>
    </Container>
  )
}

export default BackendScreen