import React from 'react'
import { Container, Row, Col, Image, Breadcrumb, BreadcrumbItem, Button } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { FaCheck } from 'react-icons/fa'
import OverImage from '../assets/images/webdesign.png'
import OverPImage from '../assets/images/over-p.png'
import WaaromImage from '../assets/images/waarom.webp'


const WebDesignScreen = () => {
  return (
    <Container>
      <header >
        <Row>
          <Col className='header-image'>
            <Image src={OverImage} fluid/>
          </Col>
        </Row>
      </header>
      <Row>
        <Col>
          <Breadcrumb>
            <LinkContainer to='/'>
              <BreadcrumbItem>
              Home
              </BreadcrumbItem>
            </LinkContainer>
            <BreadcrumbItem active>Diensten</BreadcrumbItem>
            <BreadcrumbItem active>Web Design</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <h1 className='section-header'>Onze Webontwerpservice</h1>
        <section className="section-body">
          <Col>
            <p className='section-paragraph text-justify'>
            Bij Erjel geloven we dat een boeiende en gebruiksvriendelijke website de hoeksteen is van een succesvolle online aanwezigheid. Bij onze website-ontwerpservice draait het allemaal om het vertalen van de identiteit, visie en doelstellingen van uw merk naar een visueel verbluffend en zeer functioneel digitaal platform. We begrijpen dat uw website vaak de eerste interactie is die potentiële klanten met uw bedrijf hebben, en we doen er alles aan om die eerste indruk te laten tellen.
            </p>
          </Col>
          <Col>
            <Image src={OverPImage} thumbnail />
          </Col>
        </section>
      </Row>
      <Row>
        <h1 className='section-header'>Onze Aanpak</h1>
        <section className="section-body">
          <Col>
            <Image src={WaaromImage} thumbnail />
          </Col>
          <Col>
          <h4><FaCheck /> Ontwerpen op Maat</h4>
            <p className='section-paragraph text-justify'>
            Wij geloven niet in one-size-fits-all oplossingen. Onze ervaren ontwerpers werken nauw met u samen om uw merk, branche en doelgroep te begrijpen. Vervolgens creëren we een uniek ontwerpconcept dat aansluit bij de persoonlijkheid van uw merk en resoneert met uw klanten.
            </p>
          <h4><FaCheck /> Gebruikersgerichte Focus</h4>
            <p className='section-paragraph text-justify'>
            Uw website moet gemakkelijk te navigeren en aantrekkelijk zijn voor bezoekers. Onze ontwerpers geven prioriteit aan de principes van gebruikerservaring (UX) om ervoor te zorgen dat uw website intuïtief, responsief en toegankelijk is op alle apparaten. Wij maken ontwerpen die bezoekers naadloos door uw content leiden, waardoor de kans op conversie toeneemt.
            </p>
          <h4><FaCheck /> Visuele Uitmuntendheid</h4>
            <p className='section-paragraph text-justify'>
            Visuele esthetiek speelt een cruciale rol bij het trekken van de aandacht van bezoekers. We besteden nauwgezette aandacht aan elk detail, van kleurenschema's en typografie tot afbeeldingen en lay-out. Het resultaat is een visueel aantrekkelijke website die uw merkidentiteit versterkt en een blijvende indruk achterlaat.
            </p>
          <h4><FaCheck /> Mobile-First Design</h4>
            <p className='section-paragraph text-justify'>
            Omdat het merendeel van het internetverkeer afkomstig is van mobiele apparaten, ontwerpen we met een mobile-first-aanpak. Uw website ziet er feilloos uit en presteert feilloos op smartphones en tablets, waardoor een consistente gebruikerservaring op alle platforms wordt geboden.
            </p>
            <h4><FaCheck /> SEO-integratie</h4>
            <p className='section-paragraph text-justify'>
            We nemen best practices voor SEO op in het ontwerpproces, zodat uw website zoekmachinevriendelijk is. Dit omvat het optimaliseren van de paginastructuur, metatags en afbeeldingen om de zichtbaarheid van uw website in de resultaten van zoekmachines te verbeteren.
            </p>
          </Col>
        </section>
      </Row>
      <Row>
        <h1 className='section-header'>Voordelen van onze websiteontwerpservice</h1>
        <section className="section-body">
          <Col>
            <h4><FaCheck /> Verbeterd merkimago</h4>
            <p className='section-paragraph text-justify'>
            Onze ontwerpen verhogen het imago van uw merk, waardoor het gedenkwaardiger en geloofwaardiger wordt in de ogen van uw publiek.
            </p>
            <h4><FaCheck /> Verhoogde gebruikersbetrokkenheid</h4>
            <p className='section-paragraph text-justify'>
            Aantrekkelijke ontwerpelementen zorgen ervoor dat bezoekers langer op uw website blijven, waardoor de bouncepercentages worden verlaagd en de kans op conversies wordt vergroot.
            </p>
            <h4><FaCheck /> Verbeterde conversies</h4>
            <p className='section-paragraph text-justify'>
            We plaatsen op strategische wijze call-to-action-elementen en ontwerpen conversiegerichte pagina's om de impact van uw website op uw bedrijfsdoelen te maximaliseren.
            </p>
            <h4><FaCheck /> Concurrentievoordeel</h4>
            <p className='section-paragraph text-justify'>
            Een goed ontworpen website onderscheidt u van de concurrentie en positioneert uw bedrijf als leider in uw branche.
            </p>
            <h4><FaCheck /> Schaalbaarheid</h4>
            <p className='section-paragraph text-justify'>
            Onze ontwerpen zijn schaalbaar, waardoor uw website met uw bedrijf meegroeit. Of u nu klein begint of van plan bent uit te breiden, wij staan voor u klaar.
            </p>
          </Col>
          <Col>
            <Image src={WaaromImage} thumbnail />
          </Col>
        </section>
      </Row>
      <Row>
        <h1 className='section-header'>Kortom</h1>
        <section className="section-body">
          <Col >
            <Image src={OverPImage} thumbnail />
          </Col>
          <Col>
            <p className='section-paragraph text-justify'>
            Bij Erjel zijn wij van mening dat uw website er niet alleen goed uit moet zien, maar ook tastbare resultaten moet opleveren. Onze website-ontwerpservice is een holistische benadering voor het creëren van een digitaal platform dat niet alleen uw merk vertegenwoordigt, maar ook betrokkenheid en conversies stimuleert. Laat ons uw online aanwezigheid transformeren in een krachtige marketingtool die de resultaten oplevert die u verdient. Neem vandaag nog contact met ons op om aan de slag te gaan.
            </p>
          </Col>
        </section>
      </Row>
      <div className='d-grid'>
        <LinkContainer to='/contact'>
          <Button size="lg">
            {' '} Neem contact met Ons
          </Button>
        </LinkContainer>
      </div>
    </Container>
  )
}

export default WebDesignScreen