import React, { useState, useEffect, useCallback } from 'react';
import { Image } from 'react-bootstrap'

const ImageSlider = ({ images, autoSlideInterval = 5000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = useCallback(() => {
    const newIndex = (currentIndex + 1) % images.length;
    setCurrentIndex(newIndex);
  }, [currentIndex, images.length]);

  const prevSlide = () => {
    const newIndex = (currentIndex - 1 + images.length) % images.length;
    setCurrentIndex(newIndex);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      nextSlide();
    }, autoSlideInterval);

    return () => clearInterval(intervalId);
  }, [currentIndex, autoSlideInterval, nextSlide]);

  return (
    <div className="image-slider">
      <button className='slider-button' onClick={prevSlide}>&lt;</button>
      {/* <img className='slider-img' src={images[currentIndex]} alt={`Slide ${currentIndex + 1}`} /> */}
      <Image src={images[currentIndex]} alt={`Slide ${currentIndex + 1}`} fluid/>
      <button className='slider-button' onClick={nextSlide}>&gt;</button>
    </div>
  );
};

export default ImageSlider;