import React from 'react'
import { Container, Row, Col, Image, Breadcrumb, BreadcrumbItem, Button } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { FaCheck } from 'react-icons/fa'
import OverImage from '../assets/images/mobile-app.png'
import OverPImage from '../assets/images/over-p.png'
import WaaromImage from '../assets/images/waarom.webp'


const MobileAppScreen = () => {
  return (
    <Container>
      <header >
        <Row>
          <Col className='header-image'>
            <Image src={OverImage} fluid/>
          </Col>
        </Row>
      </header>
      <Row>
        <Col>
          <Breadcrumb>
            <LinkContainer to='/'>
              <BreadcrumbItem>
              Home
              </BreadcrumbItem>
            </LinkContainer>
            <BreadcrumbItem active>Diensten</BreadcrumbItem>
            <BreadcrumbItem active>Mobile App Development</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <h1 className='section-header'>Mobile App Development</h1>
        <section className="section-body">
          <Col>
            <p className='section-paragraph text-justify'>
            In de huidige, op mobiel gerichte wereld is een sterke aanwezigheid op smartphones en tablets essentieel om uw publiek te bereiken en ermee in contact te komen. Bij Erjel zijn we gespecialiseerd in het creëren van geavanceerde mobiele applicaties die uw bedrijf, producten of diensten rechtstreeks in handen van uw klanten brengen. Onze Mobile App Development-service is gericht op het maken van intuïtieve, krachtige mobiele apps die tegemoetkomen aan een breed scala aan platforms en gebruikersvoorkeuren.
            </p>
          </Col>
          <Col>
            <Image src={OverPImage} thumbnail />
          </Col>
        </section>
      </Row>
      <Row>
        <h1 className='section-header'>Onze aanpak voor Mobile App Development</h1>
        <section className="section-body">
          <Col>
            <Image src={WaaromImage} thumbnail />
          </Col>
          <Col>
          <h4><FaCheck /> Platformdiversiteit</h4>
            <p className='section-paragraph text-justify'>
            We ontwikkelen mobiele apps voor zowel Android- als iOS-platforms, zodat uw app een brede gebruikersbasis kan bereiken. Of u nu een native app, een hybride app of een progressieve webapp nodig heeft, wij hebben de expertise om u deze te bieden.
            </p>
          <h4><FaCheck /> Gebruikersgericht ontwerp</h4>
            <p className='section-paragraph text-justify'>
            Gebruikerservaring (UX) staat voorop in onze ontwerpfilosofie. Onze mobiele app-ontwerpers creëren interfaces die niet alleen visueel aantrekkelijk zijn, maar ook intuïtief en gemakkelijk te navigeren, wat resulteert in een grotere gebruikersbetrokkenheid en tevredenheid.
            </p>
          <h4><FaCheck /> Prestatieoptimalisatie</h4>
            <p className='section-paragraph text-justify'>
            We optimaliseren mobiele apps op snelheid, reactievermogen en efficiëntie. Dit omvat het minimaliseren van laadtijden, het optimaliseren van code en het garanderen van soepele prestaties op verschillende apparaten en netwerkomstandigheden.
            </p>
          <h4><FaCheck /> Aangepaste functies</h4>
            <p className='section-paragraph text-justify'>
            Elk bedrijf is uniek en uw mobiele app moet dat weerspiegelen. We werken nauw met u samen om aangepaste functies en functionaliteiten te identificeren en te implementeren die aansluiten bij uw bedrijfsdoelen en gebruikersbehoeften.
            </p>
            <h4><FaCheck /> Beveiliging</h4>
            <p className='section-paragraph text-justify'>
            Beveiliging is een topprioriteit. We integreren robuuste beveiligingsmaatregelen, zoals gegevensversleuteling en authenticatieprotocollen, om gebruikersgegevens en de integriteit van uw app te beschermen.
            </p>
            <h4><FaCheck /> Integratie</h4>
            <p className='section-paragraph text-justify'>
            We integreren uw mobiele app naadloos met andere systemen, zoals databases, API's en webservices, om gegevenssynchronisatie en een samenhangende gebruikerservaring te garanderen.
            </p>
            <h4><FaCheck /> Schaalbaarheid</h4>
            <p className='section-paragraph text-justify'>
            Onze mobiele apps zijn gebouwd met schaalbaarheid in gedachten. Of u nu klein begint of van plan bent uit te breiden, wij ontwerpen uw app om met uw bedrijf mee te groeien.
            </p>
          </Col>
        </section>
      </Row>
      <Row>
        <h1 className='section-header'>Voordelen van onze Mobile App Development Service</h1>
        <section className="section-body">
          <Col>
            <h4><FaCheck /> Verbeterde gebruikersbetrokkenheid</h4>
            <p className='section-paragraph text-justify'>
            Mobiele apps bieden een direct en boeiend kanaal voor interactie met uw publiek, waardoor loyaliteit en terugkerende klanten worden bevorderd.
            </p>
            <h4><FaCheck /> Merkzichtbaarheid</h4>
            <p className='section-paragraph text-justify'>
            Het hebben van een speciale app met uw logo op de apparaten van gebruikers versterkt de merkherkenning en geloofwaardigheid.
            </p>
            <h4><FaCheck /> Verbeterde toegankelijkheid</h4>
            <p className='section-paragraph text-justify'>
            Mobiele apps zijn altijd en overal toegankelijk, zelfs als gebruikers offline zijn, en bieden continue toegang tot uw inhoud of services.
            </p>
            <h4><FaCheck /> Verbeterde efficiëntie</h4>
            <p className='section-paragraph text-justify'>
            Mobiele apps kunnen processen stroomlijnen en taken zoals boeken, winkelen of communicatie vergemakkelijken, waardoor het gebruikersgemak wordt vergroot.
            </p>
            <h4><FaCheck /> Analyses en inzichten</h4>
            <p className='section-paragraph text-justify'>
            Verkrijg waardevolle inzichten in gebruikersgedrag en app-prestaties door middel van analyses, zodat u datagestuurde beslissingen kunt nemen voor app-optimalisatie.
            </p>
            <h4><FaCheck /> Mogelijkheden om inkomsten te genereren</h4>
            <p className='section-paragraph text-justify'>
            Indien van toepassing kunnen mobiele apps inkomstenstromen genereren via in-app-aankopen, advertenties of abonnementsmodellen.
            </p>
          </Col>
          <Col>
            <Image src={WaaromImage} thumbnail />
          </Col>
        </section>
      </Row>
      <Row>
        <h1 className='section-header'>Kortom</h1>
        <section className="section-body">
          <Col>
            <Image src={OverPImage} thumbnail />
          </Col>
          <Col>
            <p className='section-paragraph text-justify'>
            Bij Erjel geloven we dat een goed ontworpen mobiele app een game-changer voor uw bedrijf kan zijn. Onze Mobile App Development-service is ontworpen om u een concurrentievoordeel te bieden in het mobiele landschap, zodat u op een zinvollere manier met uw publiek kunt communiceren en nieuwe groeimogelijkheden kunt ontsluiten. Of u nu een specifiek app-idee heeft of de mogelijkheden van mobiele technologie wilt verkennen, ons team staat klaar om uw visie werkelijkheid te laten worden. Neem vandaag nog contact met ons op om uw behoeften op het gebied van de ontwikkeling van mobiele apps te bespreken en de eerste stap naar mobiel succes te zetten.
            </p>
          </Col>
        </section>
      </Row>
      <div className='d-grid'>
        <LinkContainer to='/contact'>
          <Button size="lg">
            {' '} Neem contact met Ons
          </Button>
        </LinkContainer>
      </div>
    </Container>
  )
}

export default MobileAppScreen