import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
// import './PrivacyPolicy.css'; // Import your styles

const PrivacyPolicy = () => {
  return (
    <Container className="privacy-policy">
      <Row>
        <Col md={8}>
          <h1>Privacybeleid voor Erjel</h1>
          <p>Bij Erjel, bereikbaar via www.erjel.nl, is de privacy van onze bezoekers een van onze belangrijkste prioriteiten. Dit Privacybeleid-document bevat soorten informatie die door Erjel wordt verzameld en vastgelegd en hoe we deze gebruiken.</p>
          <p>Als u nog vragen heeft of meer informatie wenst over ons privacybeleid, aarzel dan niet om contact met ons op te nemen.</p>
          <p>Dit privacybeleid is alleen van toepassing op onze online activiteiten en is geldig voor bezoekers van onze website met betrekking tot de informatie die zij in Erjel hebben gedeeld en/of verzameld. Dit beleid is niet van toepassing op informatie die offline of via andere kanalen dan deze website wordt verzameld.</p>

          <h3 id='section1'>Toestemming</h3>
          <p>Door onze website te gebruiken, stemt u hierbij in met ons privacybeleid en gaat u akkoord met de voorwaarden ervan.</p>

          <h3 id='section2'>Informatie die we verzamelen</h3>
          <p>De persoonlijke gegevens die u wordt gevraagd te verstrekken, en de redenen waarom u wordt gevraagd deze te verstrekken, zullen u duidelijk worden gemaakt op het moment dat wij u vragen om uw persoonlijke gegevens te verstrekken. 
            <br />Als u rechtstreeks contact met ons opneemt, kunnen wij aanvullende informatie over u ontvangen, zoals uw naam, e-mailadres, telefoonnummer, de inhoud van het bericht en/of de bijlagen die u ons stuurt, en alle andere informatie die u ons verstrekt.
            <br /> Wanneer u zich registreert voor een Account, kunnen wij u om uw contactgegevens vragen, waaronder zaken als naam, bedrijfsnaam, adres, e-mailadres en telefoonnummer.</p>

          <h3 id='section3'>Hoe wij uw gegevens gebruiken?</h3>
          <h6>We gebruiken de informatie die we verzamelen op verschillende manieren, waaronder:</h6>
          <ul>
            <li>Leveren, exploiteren en onderhouden van onze website</li>
            <li>Verbeter, personaliseer en breid onze website uit</li>
            <li>Begrijp en analyseer hoe u onze website gebruikt</li>
            <li>Ontwikkel nieuwe producten, diensten, functies en functionaliteit</li>
            <li>Met u communiceren, rechtstreeks of via een van onze partners, onder meer voor klantenservice, om u te voorzien van updates en andere informatie met betrekking tot de website, en voor marketing- en promotiedoeleinden</li>
            <li>Stuur je e-mails</li>
            <li>Fraude opsporen en voorkomen</li>
          </ul>

          <h3 id='section4'>Logboek bestanden</h3>
          <p>Erjel volgt een standaardprocedure voor het gebruik van logbestanden. Deze bestanden registreren bezoekers wanneer ze websites bezoeken. Alle hostingbedrijven doen dit en een deel van de analyses van hostingdiensten. De informatie die door logbestanden wordt verzameld, omvat internetprotocoladressen (IP), browsertype, internetprovider (ISP), datum- en tijdstempel, verwijzende/exitpagina's en mogelijk het aantal klikken. Deze zijn niet gekoppeld aan informatie die persoonlijk identificeerbaar is. Het doel van de informatie is het analyseren van trends, het beheren van de site, het volgen van de bewegingen van gebruikers op de website en het verzamelen van demografische informatie.</p>
          
          <h3 id='section5'>Cookies en webbakens</h3>
          <p>Net als elke andere website maakt Erjel gebruik van "cookies". Deze cookies worden gebruikt om informatie op te slaan, waaronder de voorkeuren van bezoekers en de pagina's op de website die de bezoeker heeft bezocht of bezocht. De informatie wordt gebruikt om de gebruikerservaring te optimaliseren door de inhoud van onze webpagina's aan te passen op basis van het browsertype van bezoekers en/of andere informatie.</p>
          
          <h3 id='section6'>Privacybeleid van advertentiepartners</h3>
          <p>U kunt deze lijst raadplegen om het privacybeleid van elk van de advertentiepartners van Erjel te vinden.</p>
            <br />Advertentieservers of advertentienetwerken van derden gebruiken technologieën zoals cookies, JavaScript of webbakens die worden gebruikt in hun respectievelijke advertenties en links die op Erjel verschijnen en die rechtstreeks naar de browser van de gebruiker worden verzonden. Zij ontvangen automatisch uw IP-adres wanneer dit gebeurt. Deze technologieën worden gebruikt om de effectiviteit van hun advertentiecampagnes te meten en/of om de advertentie-inhoud die u ziet op websites die u bezoekt te personaliseren.
            <br />Houd er rekening mee dat Erjel geen toegang heeft tot of controle heeft over deze cookies die worden gebruikt door externe adverteerders.

          <h3 id='section7'>Privacybeleid van derden</h3>
          <p>Het privacybeleid van Erjel is niet van toepassing op andere adverteerders of websites. Daarom adviseren wij u om het respectieve privacybeleid van deze advertentieservers van derden te raadplegen voor meer gedetailleerde informatie. Het kan hun praktijken en instructies bevatten over hoe u zich kunt afmelden voor bepaalde opties.</p>
            <br />U kunt ervoor kiezen om cookies uit te schakelen via uw individuele browseropties. Voor meer gedetailleerde informatie over het cookiebeheer met specifieke webbrowsers kunt u deze vinden op de respectievelijke websites van de browsers.

          <h3 id='section8'>CCPA-privacyrechten (verkoop mijn persoonlijke gegevens niet)</h3>
          <h6>Volgens de CCPA hebben consumenten in Californië onder andere het recht om:</h6>
          <ul>
            <li>Verzoek dat een bedrijf dat de persoonlijke gegevens van een consument verzamelt, de categorieën en specifieke stukjes persoonlijke gegevens bekendmaakt die een bedrijf over consumenten heeft verzameld.</li>
            <li>Verzoek dat een bedrijf alle persoonlijke gegevens over de consument verwijdert die een bedrijf heeft verzameld.</li>
            <li>Verzoek dat een bedrijf dat de persoonlijke gegevens van een consument verkoopt, de persoonlijke gegevens van de consument niet verkoopt.</li>
            <li>Als u een verzoek indient, hebben wij één maand de tijd om op u te reageren. Als u een van deze rechten wilt uitoefenen, kunt u contact met ons opnemen.</li>
          </ul>

          <h3 id='section9'>AVG-gegevensbeschermingsrechten (GDPR Data Protection Rights)</h3>
          <h6>Wij willen er zeker van zijn dat u volledig op de hoogte bent van al uw gegevensbeschermingsrechten. Elke gebruiker heeft recht op het volgende:</h6>
          <ul>
            <li>Het recht op toegang – U heeft het recht om kopieën van uw persoonlijke gegevens op te vragen. Voor deze service kunnen wij u een kleine vergoeding vragen.</li>
            <li>Het recht op rectificatie – U heeft het recht om te verzoeken dat wij alle informatie corrigeren waarvan u denkt dat deze onjuist is. U heeft ook het recht om ons te verzoeken de informatie aan te vullen waarvan u denkt dat deze onvolledig is.</li>
            <li>Het recht op verwijdering – U heeft onder bepaalde voorwaarden het recht om te verzoeken dat wij uw persoonlijke gegevens verwijderen.</li>
            <li>Het recht om de verwerking te beperken – U heeft het recht om ons te verzoeken de verwerking van uw persoonlijke gegevens te beperken, onder bepaalde voorwaarden.</li>
          <li>Het recht om bezwaar te maken tegen de verwerking – U hebt onder bepaalde voorwaarden het recht om bezwaar te maken tegen onze verwerking van uw persoonlijke gegevens.</li>
          <li>Het recht op gegevensoverdraagbaarheid – U heeft het recht om te verzoeken dat wij de gegevens die wij hebben verzameld, onder bepaalde voorwaarden overdragen aan een andere organisatie, of rechtstreeks aan u.</li>
          <li>Als u een verzoek indient, hebben wij één maand de tijd om op u te reageren. Als u een van deze rechten wilt uitoefenen, kunt u contact met ons opnemen.</li>
          </ul>

          <h3 id='section10'>Gegevens van Kinderen</h3>
          <p>Een ander onderdeel van onze prioriteit is het toevoegen van bescherming voor kinderen tijdens het internetgebruik. We moedigen ouders en voogden aan om hun online activiteiten te observeren, eraan deel te nemen en/of te monitoren en te begeleiden.</p>
          <p>Erjel verzamelt niet bewust persoonlijk identificeerbare informatie van kinderen jonger dan 13 jaar. Als u denkt dat uw kind dit soort informatie op onze website heeft verstrekt, raden wij u ten zeerste aan onmiddellijk contact met ons op te nemen en zullen wij ons best doen om deze onmiddellijk te verwijderen dergelijke informatie uit onze administratie.</p>

          <h3 id='section11'>Wijzigingen in dit privacybeleid</h3>
          <p>We kunnen ons privacybeleid van tijd tot tijd bijwerken. Daarom raden wij u aan deze pagina regelmatig te controleren op eventuele wijzigingen. Wij zullen u op de hoogte stellen van eventuele wijzigingen door het nieuwe privacybeleid op deze pagina te plaatsen. Deze wijzigingen zijn onmiddellijk van kracht nadat ze op deze pagina zijn geplaatst.</p>

          <h3 id='section12'>Neem contact met ons op</h3>
          <p>Als u vragen of suggesties heeft over ons privacybeleid, aarzel dan niet om contact met ons op te nemen.</p>
        </Col>
        <Col md={4} className="sidebar">
          <h4>Quick Links</h4>
          <ul>
            <li><a href="#section1">Toestemming</a></li>
            <li><a href="#section2">Informatie die we verzamelen</a></li>
            <li><a href="#section3">Hoe wij uw gegevens gebruiken?</a></li>
            <li><a href="#section4">Logboek bestanden</a></li>
            <li><a href="#section5">Cookies en webbakens</a></li>
            <li><a href="#section6">Privacybeleid van advertentiepartners</a></li>
            <li><a href="#section7">Privacybeleid van derden</a></li>
            <li><a href="#section8">CCPA-privacyrechten (verkoop mijn persoonlijke gegevens niet)</a></li>
            <li><a href="#section9">AVG-gegevensbeschermingsrechten (GDPR Data Protection Rights)</a></li>
            <li><a href="#section10">Gegevens van Kinderen</a></li>
            <li><a href="#section11">Wijzigingen in dit privacybeleid</a></li>
            <li><a href="#section12">Neem contact met ons op</a></li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
};

export default PrivacyPolicy;