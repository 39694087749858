import React, {useState} from 'react'
import { Container, Col, Row, Button, Modal, Alert, Image, Breadcrumb, BreadcrumbItem } from 'react-bootstrap'
import { FaGlobe, FaMapMarkerAlt, FaPaperPlane, FaPhone, FaWhatsapp } from 'react-icons/fa'
import Iframe from 'react-iframe'
import Groningen from '../assets/images/Groningen.jpeg'
import { LinkContainer } from 'react-router-bootstrap'


import './ContactScreen.css'
import '../assets/bootstrap.min.css'

const ContactScreen = () => {
  const [state, setState] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const [success, setSuccess] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalTitel, setModalTitel] = useState('');
  const [modalText, setModalText] = useState('');

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);


  const { name, email, subject, message } = state;
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name || !email || !subject || !message) {
      setModalTitel('Let op!')
      setModalText('Alles verplicht om in te vullen')
      handleShowModal(true)
    } else {
      setModalTitel('Bedankt voor uw bericht!')
      setModalText('Uw bericht is succesvol verzonden!')
      handleShowModal(true)
      setSuccess(true)
    }
  };

  const handleInputChange = (e) => {
    let { name, value } = e.target;
    setState({ ...state, [name]: value });
  };
  return (
    <>
    {
      handleShowModal && 
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>{modalTitel}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalText}</Modal.Body>
      </Modal>
    }
      <section className="contact-section">
        <Container>
          <Image src={Groningen} fluid/>
          {/* Here starts Breadcrumb */}
          <Row>
            <Col>
              <Breadcrumb>
                <LinkContainer to='/'>
                  <BreadcrumbItem>
                      Home
                  </BreadcrumbItem>
                </LinkContainer>
                <BreadcrumbItem active>Contact</BreadcrumbItem>
              </Breadcrumb>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col className="md-10">
              <h1 align='center' className='section-header justify-content-center'>Neem Contact met Ons</h1>
              <div className="wrapper">
                <Row className="no-gutters">
                  <Col className="md-6">
                    <div className="contact-wrap w-100 p-lg-5 p-4">
                      {
                        success ? 
                        <>
                          <h3 className="mb-4">Hartelijk dank voor uw bericht!</h3>
                          <Alert variant='light'>
                            <Alert.Heading>Uw bericht is onderstaande</Alert.Heading>
                            <hr />
                            <p>
                              Naam: {state.name} <br/>
                              Email: {state.email} <br/>
                              Subject: {state.subject} <br/>
                              Bericht: {state.message} <br/>
                            </p>
                            <hr />
                            <Alert.Heading>We gaan binnen 24 uur op uw bericht reageren.</Alert.Heading>
                            <hr />
                          </Alert>
                        </>
                        :
                        <>
                          <h3 className="mb-4">Stuur een bericht</h3>
                          <form
                            id="contactForm"
                            className="contactForm"
                            onSubmit={handleSubmit}
                            netlify-honeypot='bot-field'
                            data-netlify='true'
                          >
                            <input type="hidden" className='hidden' name="form-name" value="contact" />
                            <Row>
                              <Col className="md-12">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="name"
                                    placeholder="Naam"
                                    onChange={handleInputChange}
                                    value={name}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="md-12">
                                <div className="form-group">
                                  <input
                                    type="email"
                                    className="form-control"
                                    name="email"
                                    placeholder="Email"
                                    onChange={handleInputChange}
                                    value={email}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="md-12">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="subject"
                                    placeholder="Subject"
                                    onChange={handleInputChange}
                                    value={subject}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row> 
                              <Col className="md-12">
                                <div className="form-group">
                                  <textarea
                                    type="text"
                                    className="form-control"
                                    name="message"
                                    placeholder="Bericht hier"
                                    cols="30"
                                    rows="6"
                                    onChange={handleInputChange}
                                    value={message}
                                  ></textarea>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="md-12">
                                <div className="form-group d-grid">
                                  <input
                                    type="submit"
                                    value="Verzenden"
                                    className="btn btn-primary"
                                  />
                                </div>
                              </Col>
                            </Row>
                          </form>
                        </>
                      }
                    </div>
                  </Col>
                  <Col className="md-6 d-flex align-items-stretch">
                    <div className="info-wrap w-100 p-lg-5 p-4">
                      <h3>Wij wachten op u</h3>
                      <p className="mb-4">
                        Erjel is dol op zijn klanten, dus arzel niet om ons tijdens werktijden te bezoeken
                      </p>
                      <div className="d-grid gap-1">
                        <Button href='https://maps.app.goo.gl/n6myWYvH5vDNVhXt9' target='_blank' size="lg">
                          <FaMapMarkerAlt className='icon-size' />
                          {' '} Adres
                        </Button>
                        <Button href='https://wa.me/31630527571?text=Momenteel neem ik contact met je op via uw website.' target='_blank' size="lg">
                          <FaWhatsapp className='icon-size' />
                          {' '} WhatsApp
                        </Button>
                        <Button href='tel:+31630527571' size="lg">
                          <FaPhone className='icon-size' />
                          {' '} Telefoon
                        </Button>
                        <Button href='mailto:info@erjel.nl'size="lg">
                          <FaPaperPlane className='icon-size' />
                          {' '} Email
                        </Button>
                        <Button href='http://erjel.nl' target='_blank' size="lg">
                          <FaGlobe className='icon-size' />
                          {' '} Website
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="contact-section">
        <Container>
          <Row className="justify-content-center">
            <Col className="md-10">
              <h1 align='center' className='section-header'>Bezoek adres en werktijden</h1>
              <div className="wrapper">
                <Row className="no-gutters">
                  <Col className="md-6">
                    <div className="info-wrap w-100 p-lg-5 p-4">
                      <h3 className="mb-4">Bezoek Locatie</h3>
                        <Iframe 
                        src={'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2390.8983058114327!2d6.597500776234276!3d53.18380407224266!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c82d7447825039%3A0x5937c40691e6949d!2sErjel%20-%20Webprojectmanagement%20en%20Advies!5e0!3m2!1sen!2snl!4v1702649597512!5m2!1sen!2snl'}
                        width={'100%'} 
                        height={400} 
                        style={{border:0}} 
                        loading={'lazy'} 
                        referrerPolicy={'no-referrer-when-downgrade'}
                        >
                        </Iframe>
                    </div>
                  </Col>
                  <Col className="md-6 d-flex align-items-stretch">
                    <div className="contact-wrap w-100 p-lg-5 p-4">
                      <h3>Werktijden</h3>
                      {/* <p className="mb-4">
                        Erjel is dol op zijn klanten, dus arzel niet om ons tijdens werktijden te bezoeken
                      </p> */}
                      <div className="d-grid gap-1">
                        <Button size="lg">
                          {' '} Maandag: 08:30 - 18:00
                        </Button>
                        <Button size="lg">
                          {' '} Dinsdag: 08:30 - 18:00
                        </Button>
                        <Button size="lg">
                          {' '} Woensdag: 08:30 - 18:00
                        </Button>
                        <Button size="lg">
                          {' '} Donderdag: 08:30 - 18:00
                        </Button>
                        <Button size="lg">
                          {' '} Vrijdag: 08:30 - 18:00
                        </Button>
                        <Button size="lg">
                          {' '} Zaterdag en Zondag: Gesloten
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default ContactScreen