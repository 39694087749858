import React from 'react'
import { Container, Row, Col, Image, Breadcrumb, BreadcrumbItem, Button } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { FaCheck } from 'react-icons/fa'
import OverImage from '../assets/images/frontend.png'
import OverPImage from '../assets/images/over-p.png'
import WaaromImage from '../assets/images/waarom.webp'

const FrontendScreen = () => {
  return (
    <Container>
      <header >
        <Row>
          <Col className='header-image'>
            <Image src={OverImage} fluid/>
          </Col>
        </Row>
      </header>
      <Row>
        <Col>
          <Breadcrumb>
            <LinkContainer to='/'>
              <BreadcrumbItem>
                  Home
              </BreadcrumbItem>
            </LinkContainer>
            <BreadcrumbItem active>Diensten</BreadcrumbItem>
            <BreadcrumbItem active>Front-End Development</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <h1 className='section-header'>Front-End Development</h1>
        <section className="section-body">
          <Col>
            <p className='section-paragraph text-justify'>
              Front-End Development is de kunst van het tot leven brengen van website-ontwerpen, waarbij creatieve concepten worden omgezet in interactieve, gebruiksvriendelijke en responsieve webinterfaces. Bij Erjel zijn we trots op onze Front-End Development-service, waarbij innovatie en functionaliteit samenkomen om websites te creëren die er niet alleen prachtig uitzien, maar ook een naadloze gebruikerservaring bieden.
            </p>
          </Col>
          <Col>
            <Image src={OverPImage} thumbnail />
          </Col>
        </section>
      </Row>
      <Row>
        <h1 className='section-header'>Onze Front-End ontwikkelingsexpertise</h1>
        <section className="section-body">
          <Col>
            <Image src={WaaromImage} thumbnail />
          </Col>
          <Col>
          <h4><FaCheck /> Responsive Design</h4>
            <p className='section-paragraph text-justify'>
              In de huidige wereld met meerdere apparaten is het van het grootste belang dat uw website feilloos werkt op verschillende schermformaten. Onze Front-End Developers blinken uit in het creëren van responsieve ontwerpen die zich aanpassen aan desktops, laptops, tablets en smartphones, waardoor een optimale gebruikerservaring voor alle bezoekers wordt gegarandeerd.
            </p>
          <h4><FaCheck /> Cross-Browser-Compatibiliteit</h4>
            <p className='section-paragraph text-justify'>
              We begrijpen dat verschillende webbrowsers code verschillend interpreteren. Onze ontwikkelaars testen websites rigoureus in meerdere browsers, zodat uw website consistent en foutloos functioneert voor alle gebruikers, ongeacht hun voorkeursbrowser.
            </p>
          <h4><FaCheck /> Interactieve Elementen</h4>
            <p className='section-paragraph text-justify'>
              Interactieve elementen voegen een laag betrokkenheid toe aan uw website. Of het nu gaat om vloeiende animaties, interactieve formulieren of dynamische inhoud, onze Front-End Developers zijn bedreven in het integreren van deze functies om uw publiek te boeien en interactie te stimuleren.
            </p>
          <h4><FaCheck /> Prestatieoptimalisatie</h4>
            <p className='section-paragraph text-justify'>
              Langzaam ladende websites kunnen gebruikers afschrikken en een negatieve invloed hebben op de ranking van zoekmachines. We optimaliseren de prestaties van uw website door de laadtijden van pagina's te minimaliseren, onnodige code te verminderen en gebruik te maken van best practices uit de branche.
            </p>
            <h4><FaCheck /> Toegankelijkheid</h4>
            <p className='section-paragraph text-justify'>
              Webtoegankelijkheid is een prioriteit. We houden ons aan de WCAG-normen (Web Content Accessibility Guidelines) en zorgen ervoor dat uw website toegankelijk is voor gebruikers met een beperking, waardoor uw inhoud beschikbaar wordt voor een breder publiek en de inclusiviteit wordt vergroot.
            </p>
            <h4><FaCheck /> Beveiliging</h4>
            <p className='section-paragraph text-justify'>
              Onze front-endontwikkelaars implementeren best practices op het gebied van beveiliging om uw website te beschermen tegen veelvoorkomende kwetsbaarheden, waardoor de veiligheid van gebruikersgegevens wordt gegarandeerd en de reputatie van uw merk behouden blijft.
            </p>
            <h4><FaCheck /> Contentintegratie</h4>
            <p className='section-paragraph text-justify'>
              We integreren uw website naadloos met Content Management Systemen (CMS) of andere back-endsystemen, zodat u uw inhoud eenvoudig kunt bijwerken en beheren zonder technische expertise.
            </p>
          </Col>
        </section>
      </Row>
      <Row>
        <h1 className='section-header'>Voordelen van onze Front-end ontwikkelingsservice</h1>
        <section className="section-body">
          <Col>
            <h4><FaCheck /> Uitzonderlijke gebruikerservaring</h4>
            <p className='section-paragraph text-justify'>
              Onze front-endoplossingen geven prioriteit aan de gebruikerservaring, wat resulteert in lagere bouncepercentages en een grotere gebruikersbetrokkenheid.
            </p>
            <h4><FaCheck /> Verbeterde SEO</h4>
            <p className='section-paragraph text-justify'>
              Een goed geoptimaliseerde front-end draagt bij aan een betere ranking in zoekmachines, waardoor de zichtbaarheid van uw website wordt vergroot en organisch verkeer wordt gegenereerd.
            </p>
            <h4><FaCheck /> Verbeterde merkconsistentie</h4>
            <p className='section-paragraph text-justify'>
              Wij zorgen ervoor dat uw website uw merkidentiteit consistent weerspiegelt op alle pagina’s en apparaten, waardoor de merkherkenning wordt versterkt.
            </p>
            <h4><FaCheck /> Mobiele optimalisatie</h4>
            <p className='section-paragraph text-justify'>
              Nu het mobiele gebruik toeneemt, garandeert onze mobile-first-aanpak dat uw website uitzonderlijk goed presteert op smartphones en tablets.
            </p>
            <h4><FaCheck /> Hogere conversiepercentages</h4>
            <p className='section-paragraph text-justify'>
              Geoptimaliseerde gebruikersinterfaces en intuïtieve navigatiepaden leiden tot verbeterde conversiepercentages, waardoor uw online succes wordt vergroot.
            </p>
          </Col>
          <Col>
            <Image src={WaaromImage} thumbnail />
          </Col>
        </section>
      </Row>
      <Row>
        <h1 className='section-header'>Kortom</h1>
        <section className="section-body">
          <Col>
            <Image src={OverPImage} thumbnail />
          </Col>
          <Col>
            <p className='section-paragraph text-justify'>
              Bij Erjel is onze Front-End Development dienst de brug die jouw visie verbindt met de digitale wereld. We zijn er trots op dat we front-endoplossingen ontwerpen die niet alleen aan uw ontwerpverwachtingen voldoen, maar ook de functionaliteit en prestaties van uw website verbeteren. Laat ons uw ideeën omzetten in een boeiende en interactieve online ervaring. Neem vandaag nog contact met ons op om aan de slag te gaan met uw volgende webontwikkelingsproject.
            </p>
          </Col>
        </section>
      </Row>
      <div className='d-grid'>
        <LinkContainer to='/contact'>
          <Button size="lg">
            {' '} Neem contact met Ons
          </Button>
        </LinkContainer>
      </div>
    </Container>
  )
}

export default FrontendScreen