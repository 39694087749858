import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider
} from 'react-router-dom'
import './assets/index.css';
import './assets/bootstrap.min.css';
import reportWebVitals from './reportWebVitals';
import App from './App';
import HomeScreen from './screens/HomeScreen';
import ContactScreen from './screens/ContactScreen';
import AboutScreen from './screens/AboutScreen';
import ServiceScreen from './screens/ServiceScreen';
import WebDesignScreen from './screens/WebDesignScreen'
import FrontendScreen from './screens/FrontendScreen';
import BackendScreen from './screens/BackendScreen';
import EcommerceScreen from './screens/EcommerceScreen';
import MobileAppScreen from './screens/MobileAppScreen';
import CMSScreen from './screens/CMSScreen';
import SEOScreen from './screens/SEOScreen';
import PrivacyPolicy from './screens/PrivacyPolicy';
import NotFound from './screens/NotFound';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<App />}>
      <Route index={true} path='/' element={<HomeScreen />} />
      <Route index={true} path='/contact' element={<ContactScreen />} />
      <Route index={true} path='/about' element={<AboutScreen />} />
      <Route index={true} path='/service' element={<ServiceScreen />} />
      <Route index={true} path='/webdesign' element={<WebDesignScreen />} />
      <Route index={true} path='/frontend' element={<FrontendScreen />} />
      <Route index={true} path='/backend' element={<BackendScreen />} />
      <Route index={true} path='/ecommerce' element={<EcommerceScreen />} />
      <Route index={true} path='/mobileapp' element={<MobileAppScreen />} />
      <Route index={true} path='/cmsscreen' element={<CMSScreen />} />
      <Route index={true} path='/seoscreen' element={<SEOScreen />} />
      <Route index={true} path='/privacy-policy' element={<PrivacyPolicy />} />
      <Route path='*' element={<NotFound />} />
    </Route>
  )
)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
