import React from 'react'
import { Container, Row, Col, Image, Breadcrumb, BreadcrumbItem } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { FaCheck } from 'react-icons/fa'
import OverImage from '../assets/images/over.png'
import OverPImage from '../assets/images/over-p.png'
import WaaromImage from '../assets/images/waarom.webp'

const AboutScreen = () => {
  return (
    <Container>
      <header >
        <Row>
          <Col className='header-image'>
            <Image src={OverImage} fluid/>
          </Col>
        </Row>
      </header>
      <Row>
        <Col>
          <Breadcrumb>
            <LinkContainer to='/'>
              <BreadcrumbItem>
                  Home
              </BreadcrumbItem>
            </LinkContainer>
            <BreadcrumbItem active>Over Erjel</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <h1 className='section-header'>Over Erjel</h1>
        <section className="section-body">
          <Col>
          <p className='section-paragraph text-justify'>
            Erjel is een professioneel ontwikkelingsbedrijf dat zich toegelegt op het creëren van innovatieve en effectieve weboplossingen voor bedrijven van elke omvang.
            Met een team van zeer bekwame ontwikkelaars en ontwerpers is Erjel gespecialiseerd in het creëren van responsieve, gebruiksvriendelijke en visueel verbluffende websites die resultaten opleveren en uw online aanwezigheid naar hoger niveau tillen.
            Als u nu een startup bent die uw digitale voetafdruk wilt vestigen of een gevestigd merk dat op zoek is naar een vernieuwing van de website, heeft Erjel de expertise om uw visie tot leven te brengen.
            </p>
          </Col>
          <Col>
            <Image src={OverPImage} thumbnail />
          </Col>
        </section>
      </Row>
      <Row>
        <h1 className='section-header'>Waarom Erjel?</h1>
        <section className="section-body">
          <Col>
            <Image src={WaaromImage} thumbnail />
          </Col>
          <Col>
            <h4><FaCheck /> Ervaring</h4>
            <p className='section-paragraph text-justify'>
            Hoewel Erjel in Nederland nieuw is, heeft Erjel meer dan 10 jaar ervaring in de sector met een bewezen staat van dienst in het leveren van succesvolle webprojecten voor een breed scala aan klanten.
            </p>
            <h4><FaCheck /> Kwaliteitsborging</h4>
            <p className='section-paragraph text-justify'>
            Strenge tests en kwaliteitscontroles zorgen ervoor dat uw website feilloos functioneert en een uitzonderlijke gebruikerservaring biedt.
            </p>
            <h4><FaCheck /> Oplossingen op Maat</h4>
            <p className='section-paragraph text-justify'>
            Erjel begrijpt dat elk bedrijf uniek is. Zijn oplossingen zijn op maat gemaakt om aan te sluiten bij uw doelen en doelstellingen.
            </p>
            <h4><FaCheck /> Toegewijde Ondersteuning</h4>
            <p className='section-paragraph text-justify'>
            De toewijding van Erjel aan klanttevredenheid eindigt niet bij de oplevering van het project. Erjel levert voortdurende ondersteuning en onderhoud om uw website soepel te laten werken.
            </p>
            <h4><FaCheck /> Betaalbare Prijzen</h4>
            <p className='section-paragraph text-justify'>
            Erjel biedt concurrerende prijspakketten zonder concessies te doen aan de kwaliteit, waardoor onze diensten toegankelijk zijn voor bedrijven van elke omvang.
            </p>
          </Col>
        </section>
      </Row>
    </Container>
  )
}

export default AboutScreen